import React from "react"
import Layout from "../layout"

class eetoPage extends React.Component {
  render() {
    return (
      <Layout title='GET YOUR 88x31 TODAY'>
        <div className="eeto has-text-centered">
          { [...Array(18)].map((e, i) => <img key={i} src="/img/8831/button-now.gif" alt="button Now!" />) }
        </div>
          <h1 style={{fontSize: "8em", marginTop: "4px"}}>
            Get your 88x31 button <span className="has-text-danger is-italic">Now!</span>
          </h1>
        <div className="eeto has-text-centered">
          { [...Array(18)].map((e, i) => <img key={i} src="/img/8831/button-now.gif" alt="button Now!" />) }
        </div>

          <ul>
            <li>You may ask, Why do I need a button Now?? </li>
          </ul>

          <h1>
            <ul>
              <li>be a part of your own web ring with the people you like, just like the good ol' days of the internet!
                 <span className="is-size-5"> search engines are overrated.. <img class="emote" src="/img/emotes/cheers.gif"></img> </span>
                 </li>
              <li>... and be part of the <s>cult</s> thriving network of <s>otakus and bronies</s> <a href="https://eightyeightthirty.one/">eightyeightthirty.one</a> !</li>
              <li>make people think wow!! <s>what a nerd</s> how COOL you are! </li>
            </ul>
          </h1>
          
          <h1 style={{fontSize: "3em"}}>
            here's how you can ~*make your own*~ button <span className="has-text-danger is-italic">Now!</span>
          </h1>
            <ol>
              <li>download this template: <a href="" target="_blank" rel="noopener noreferrer" download="8831-template.png">
                  <img className="icon" src="/img/icons/download.svg" alt="download" />
                  <img src="/img/8831/8831-template.png" alt="template"/>
                </a>
              </li>
              <li>go to your favorite <a href="https://www.piskelapp.com/">pixel drawing app</a></li>
              <li>create!!</li>
              <li>put on your website; don't forget to use css <code>image-rendering: pixelated</code></li>
              <li>let me know you made one so i can put it on mine <img class="emote" src="/img/emotes/thumbsup.gif"></img>
              </li>
            </ol>
      </Layout>
    )
  }
}

export default eetoPage
